header {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 50px;
    background-color: #333;
    color: #fff;
    z-index: 1000;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 1em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.header-link {
    margin: 0 30px;
}

.header-link a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
}

/* Responsive Header Adjustments */
@media screen and (max-width: 768px) {
    .header {
        min-height: 60px;
    }

    .header-link {
        margin: 0 20px;
    }
}

@media screen and (max-width: 480px) {
    .header {
        min-height: 70px;
    }

    .header-link {
        margin: 0 10px;
    }
}