.app-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px;
}

.loader-text {
    font-size: 1.5em;
    text-shadow: 2px 2px 4px #000000;
}

.loader-display {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

@media screen and (max-width: 768px) {
    .loader-text {
        font-size: 1.25em;
    }
    
}

@media screen and (max-width: 480px) {
    .loader-text {
        font-size: 1em;
    }
}