footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 50px;
    background-color: #333;
    color: #fff;
    z-index: 1000;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 1em;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.footer-link {
    margin: 0 30px;
}

.footer-link a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
}

/* Responsive Footer Adjustments */
@media screen and (max-width: 768px) {
    .footer {
        min-height: 60px;
    }

    .footer-link {
        margin: 0 20px;
    }
}

@media screen and (max-width: 480px) {
    .footer {
        min-height: 70px;
    }

    .footer-link {
        margin: 0 10px;
    }
}