.left-side-bar-container {
    width: 275px;

    display: flex;
    flex-direction: column;
}

.user-container {
    width: 100%;
    height: 250px;
    background-color: #404040;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 0.85em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.profile-image {
    margin-top: 20px;
    width: 130px;
    height: 130px;
}

.profile-details {
    text-align: center;
    text-shadow: 1px 1px 4px #000000;
}

.skills-container {
    flex: 1;
    width: 100%;
}

.social-media-container {
    width: 100%;
    height: 50px;
    background-color: #404040;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.social-icon {
    color: white;
    text-decoration: none;
    margin: 0 15px;
}

.social-icon:hover {
    color: #818181;
}