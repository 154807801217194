@font-face {
  font-family: 'JetBrains Mono';
  src: url('./Assets/Fonts/JetBrainsMono-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;

  background-color: #1e1e1e;
  color: white;
  overflow: hidden;

  height: 100%;

  font-size: 16px;
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'JetBrains Mono', monospace;
}
