.right-side-bar-container {
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    right: 0;
    width: 75px;
    height: 100%;

    background-color: #252525;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 50%);
    transition: width 1s ease;
}

.right-side-bar-container.expanded {
    width: 275px;
    transition: width 1s ease;
}

.menu-container {
    position: relative;
    width: 100%;
    height: 70px;
    background-color: #404040;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.options-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); /* Vertically center both icons */
    color: white;
    transition: opacity 0.5s ease, transform 0.5s ease, left 0.5s ease;
    opacity: 0;
    transform: translateY(-50%);
}

.options-icon:hover {
    color: #c5c5c5
}

.menu-icon {
    left: 28%;
    transform: translate(-50%, -50%);
}

.close-icon {
    left: 10px;
}

.options-icon.visible {
    opacity: 1;
    transform: translateY(-50%);
}

.options-icon.hidden {
    opacity: 0;
    transform: translateY(-50%);
}

.tabs-container {
    flex: 1;
    width: 100%;
    margin-top: 30px;

    display: flex;
    flex-direction: column;

    position: relative;
}

.tab-link {
    opacity: 0;
    padding: 30px 0px 0px 20px;
    transition: opacity 0.5s ease;
}

.tab-link.visible {
    opacity: 1;
    margin-right: 50px;
    transition: opacity 0.5s ease-in;
}

.tab-link.hidden {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.link-span {
    color: #818181;
    font-size: 1em;
    transition: color 1s ease;
    text-shadow: 2px 2px 4px #000000;
    cursor: pointer;
}

.link-span:hover {
    color: white;
    font-size: 1em;
    transition: color 1s ease;
}

.selected-tab {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: start;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.selected-tab.visible {
    opacity: 1;
    transition: opacity 1s ease;
}

.selected-tab.hidden {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.selected-tab-span {
    color: #818181;
    font-size: 1em;
    transform: rotate(90deg);
    text-shadow: 2px -2px 4px #000;
    margin-top: 130px;
}